import { resetContract } from '@paid-ui/models/contract';
import { resetDevtoolsManager } from '@paid-ui/models/devtools';
import { resetPayment } from '@paid-ui/models/payment';
import { clearPreSignedUrls } from '@paid-ui/models/pre-signed-url';
import { resetProject } from '@paid-ui/models/project';
import { resetTransactionHistory } from '@paid-ui/models/transaction-history';
import { reset as clearProfile } from '@paid-ui/models/user';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { type QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export const postSignOut = (client: QueryClient) => {
  clearProfile();
  client.clear();
  sessionStorage.clear();
  // Reset all global models
  resetPayment();
  resetContract();
  resetProject();
  resetTransactionHistory();
  resetDevtoolsManager();
  clearPreSignedUrls();
};

export const useSignOut = () => {
  const toast = useToast();
  const router = useRouter();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['SignOut'],
    mutationFn: async () => {
      const access_token = sessionStorage.getItem('access_token');
      if (!access_token) return;
      await request.delete('/auth/signout', {
        data: {
          access_token,
        },
      });
    },
    onSuccess() {
      postSignOut(client);
      router.replace('/signin');
      toast.success('Sign out successful.');
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to sign out.');
    },
  });
};
